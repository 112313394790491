import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import About from './components/about/About';
import Home from './components/home/Home';
import RootContainer from './components/container/RootContainer';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import AlbumIcon from '@material-ui/icons/Album';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import DraftsIcon from '@material-ui/icons/Drafts';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import PublicIcon from '@material-ui/icons/Public';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import MailIcon from '@material-ui/icons/Mail';
import Events from './components/events/Events';
import Albums from './components/albums/Albums';
import Photos from './components/photos/Photos';
import Partners from './components/partners/Partners';
import InviteUs, { MessengerIcon } from './components/invite/InviteUs';
import SongBook from './components/songbook/SongBook';
import { AppleMusicIcon, DeezerIcon, SpotifyIcon, TidalIcon, YoutubeMusicIcon } from './common/icons';
import 'react-image-gallery/styles/css/image-gallery.css'
import './css/App.css';

export const pages = [
    { Icon: HomeIcon, label: 'Strona główna', path: '/' },
    { Icon: PeopleIcon, label: 'O nas', path: '/about' },
    { Icon: InsertInvitationIcon, label: 'Wydarzenia', path: '/events', },
    { Icon: AlbumIcon, label: 'Dyskografia', path: '/albums' },
    { Icon: DraftsIcon, label: 'Kontakt', path: '/invite' },
    { Icon: PhotoAlbumIcon, label: 'Galeria', path: '/photos' },
    { Icon: PublicIcon, label: 'Partnerzy', path: '/partners' },
    { Icon: ImportContactsIcon, label: 'Śpiewnik', path: '/songbook' }
];

export const mediaLinks = [
    { index: 'Youtube', MediaIcon: YouTubeIcon, path: 'https://www.youtube.com/c/AniołyNadziei', name: '/c/AniołyNadziei' },
    { index: 'Instagram', MediaIcon: InstagramIcon, path: 'https://www.instagram.com/aniolynadziei', name: '/aniolynadziei' },
    { index: 'Facebook', MediaIcon: FacebookIcon, path: 'https://www.facebook.com/aniolynadziei', name: '@aniolynadziei' }
];

export const musicMediaLinks = [
    { index: 'Youtube', MediaIcon: YouTubeIcon, path: 'https://www.youtube.com/c/AniołyNadziei', name: 'YouTube' },
    { index: 'YoutubeMusic', MediaIcon: YoutubeMusicIcon, path: 'https://music.youtube.com/channel/UClYKPj04A2-EzIcfM9q_5_Q?feature=share', name: 'YT Music' },
    { index: 'AppleMusic', MediaIcon: AppleMusicIcon, path: 'https://music.apple.com/us/artist/anio%C5%82y-nadziei/1519303993', name: 'artist/anioły-nadziei' },
    { index: 'Spotify', MediaIcon: SpotifyIcon, path: 'https://open.spotify.com/artist/3wOXk3gt9lyBXuL7o9qiZQ?si=fiFaYiXjQA6LgdgrxAL3pw', name: 'Spotify' },
    { index: 'Deezer', MediaIcon: DeezerIcon, path: 'https://deezer.page.link/iVKF4XERoQSundUa6', name: 'Deezer' },
    { index: 'Tidal', MediaIcon: TidalIcon, path: 'https://tidal.com/', name: 'Tidal' }
];

export const mediaLinksExtended = [
    ...mediaLinks,
    musicMediaLinks[2],
    musicMediaLinks[3],
    musicMediaLinks[4],
    musicMediaLinks[5],
];

export const contactDetails = [
    { Icon: MailIcon, name: 'Email', label: 'biuro@anioly-nadziei.pl', url: 'mailto:biuro@anioly-nadziei.pl' },
    { Icon: PhoneAndroidIcon, name: 'Telefon', label: '+48 601 213 585', url: 'tel:+48601213585' },
    { Icon: MessengerIcon, name: 'Messenger', label: 'm.me/aniolynadziei', url: 'http://m.me/aniolynadziei' }
];

function App() {
    return (
        <div className="App">
            <Router>
                <RootContainer pages={pages}>
                    <Switch>
                        <Route path={pages[7].path}>
                            <SongBook breadcrumbInfo={pages[7]} />
                        </Route>
                        <Route path={pages[6].path}>
                            <Partners breadcrumbInfo={pages[6]} />
                        </Route>
                        <Route path={pages[5].path}>
                            <Photos breadcrumbInfo={pages[5]} />
                        </Route>
                        <Route path={pages[4].path}>
                            <InviteUs breadcrumbInfo={pages[4]} />
                        </Route>
                        <Route path={pages[3].path}>
                            <Albums breadcrumbInfo={pages[3]} />
                        </Route>
                        <Route path={pages[2].path}>
                            <Events breadcrumbInfo={pages[2]} />
                        </Route>
                        <Route path={pages[1].path}>
                            <About breadcrumbInfo={pages[1]} />
                        </Route>
                        <Route path={pages[0].path}>
                            <Home breadcrumbInfo={pages[0]} />
                        </Route>
                    </Switch>
                </RootContainer>
            </Router>
        </div>
    );
}

export default App;
