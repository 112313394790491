import React from 'react';
import Breadcrumb from '../../common/Breadcrumb';
import { Typography, Divider } from '@material-ui/core';
import ImageGallery from 'react-image-gallery';

const images = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(name => ({
    original: `gallery/${name}.png`,
    thumbnail: `gallery/${name}.png`
})
);

const Photos = ({ breadcrumbInfo }) => {
    return (
        <div>
            <Breadcrumb Icon={breadcrumbInfo.Icon} label={breadcrumbInfo.label} />
            <Divider />
            <div className="Pages-content">
                <Typography variant="h4" gutterBottom>
                    Galeria
                </Typography>
                <ImageGallery items={images} />
            </div>
        </div>
    );
};

export default Photos;