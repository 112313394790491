import { APP_ID, APP_TOKEN, GRAPH_API_BASE_URL, GRAPH_API_VERSION } from '../..';


export function getLatestPosts() {
    return {
        method: 'GET',
        url: `${GRAPH_API_BASE_URL}/${GRAPH_API_VERSION}/${APP_ID}/posts?access_token=${APP_TOKEN}`,
        params: {
            limit: 5,
            fields: ['created_time', 'message', 'attachments'].join(',')
        }
    };
}