import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Link as InternalLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import PublicIcon from '@material-ui/icons/Public';
import Breadcrumb from '../../common/Breadcrumb';
import { Divider, Paper, IconButton, makeStyles, List, ListItem, ListItemIcon, ListItemText, Card, CardMedia, Link } from '@material-ui/core';
import { mediaLinks } from '../../App';
import { getPublicDataUrl } from '../../common/utils';
import News from './News';
import './Home.css';

const useStyles = makeStyles(theme => ({
    listRoot: {
        backgroundColor: theme.palette.background.paper,
        width: 440
    },
    iconSize: {
        fontSize: 40
    },
    secondaryText: {
        marginRight: '15px'
    }
}));

const Home = ({ breadcrumbInfo }) => {
    const classes = useStyles();
    const [homeData, setHomeData] = useState({});

    useEffect(() => {
        fetch(getPublicDataUrl('Home'))
            .then(response => response.json())
            .then(json => setHomeData(json));
    }, []);

    return (
        <div className="Home">
            <Breadcrumb Icon={breadcrumbInfo.Icon} label={breadcrumbInfo.label} />
            <Divider />
            <div className="Pages-content">
                <div className="header-image">
                    <Card>
                        <CardMedia
                            component="img"
                            alt="Banner"
                            image="img/an-page-cover-glos-maryi.jpg"
                            title="Zdjęcie tła"
                        />
                    </Card>
                </div>
                <Typography variant="h4" gutterBottom>
                    {homeData.title || "Zespół Ewangelizacyjny Anioły Nadziei"}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {homeData.description || "Naszym celem jest głoszenie Dobrej Nowiny poprzez śpiew i muzykę."}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    {homeData.findUs || "Znajdź nas w sieci:"}
                </Typography>
                <div className="Home-social-media">
                    {mediaLinks.map(({ MediaIcon, name, path }) => (
                        <Paper key={_.uniqueId('socialmedia-')} className="Home-media-link" elevation={3} component="div">
                            <IconButton color="primary" fontSize="large" component={Link} href={path} target="_blank" rel="noopener">
                                <MediaIcon style={{ fontSize: 58 }} />
                            </IconButton>
                            <Typography variant="overline" display="block" gutterBottom>
                                {name}
                            </Typography>
                        </Paper>
                    ))}
                </div>
                <Typography variant="subtitle2" gutterBottom>
                    {homeData.mediaTitle || "Znajdź nas w mediach:"}
                </Typography>
                <List component="nav" className={classes.listRoot}>
                    <ListItem
                        button
                        component={InternalLink}
                        to={'/partners'}
                    >
                        <ListItemIcon>
                            <PublicIcon className={classes.iconSize} />
                        </ListItemIcon>
                        <ListItemText
                            primary={homeData.partnersTitle || "Partnerzy Medialni"}
                        />
                    </ListItem>
                </List>
                {/* <div className="Home-social-media">
                    {(homeData.mediaData || []).map(data => (
                        <MediaCard
                            style={{ margin: '0 10px 10px 0' }}
                            height={100}
                            {...data}
                        />
                    ))}
                </div>
            </div> */}
                <Typography variant="subtitle2" gutterBottom>
                    {homeData.news || "Aktualności"}
                </Typography>
                <News />
            </div>
        </div>
    );
};

export default Home;