import React from 'react';
import Breadcrumb from '../../common/Breadcrumb';
import { Typography, Divider } from '@material-ui/core';

const Events = ({ breadcrumbInfo }) => {
    return (
        <div>
            <Breadcrumb Icon={breadcrumbInfo.Icon} label={breadcrumbInfo.label} />
            <Divider />
            <div className="Pages-content">
                <Typography variant="h4" gutterBottom>
                    Wydarzenia
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Ta strona jeszcze nie jest gotowa.
                </Typography>
            </div>
        </div>
    );
};

export default Events;