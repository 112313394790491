import _ from 'lodash';
import React from 'react';
import Breadcrumb from '../../common/Breadcrumb';
import { Typography, Divider, ListItem, ListItemIcon, ListItemText, List, makeStyles, Link, Button } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { contactDetails } from '../../App';

export function MessengerIcon (props) {
    return (
        <SvgIcon {...props}>
            <path d="M12 0c-6.627 0-12 4.975-12 11.111 0 3.497 1.745 6.616 4.472 8.652v4.237l4.086-2.242c1.09.301 2.246.464 3.442.464 6.627 0 12-4.974 12-11.111 0-6.136-5.373-11.111-12-11.111zm1.193 14.963l-3.056-3.259-5.963 3.259 6.559-6.963 3.13 3.259 5.889-3.259-6.559 6.963z" />
        </SvgIcon>
    );
}

const useStyles = makeStyles(theme => ({
    listRoot: {
        backgroundColor: theme.palette.background.paper,
        width: 440
    },
    iconSize: {
        fontSize: 40
    },
    secondaryText: {
        marginRight: '15px'
    }
}));

const copyToClipBoard = (text) => {
    var input_temp = document.createElement("input");
    input_temp.value = text;
    document.body.appendChild(input_temp);
    input_temp.select();
    document.execCommand("copy");
    document.body.removeChild(input_temp);
};

const InviteUs = ({ breadcrumbInfo }) => {
    const classes = useStyles();
    return (
        <div>
            <Breadcrumb Icon={breadcrumbInfo.Icon} label={breadcrumbInfo.label} />
            <Divider />
            <div className="Pages-content">
                <Typography variant="h4" gutterBottom>
                    Zaproś nas na koncert lub rekolekcje
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Dane kontaktowe:
                </Typography>
                <Typography variant="subtitle1">
                    Kliknij aby wysłać email, zadzwonić lub napisać do nas przez aplikację messenger.
                </Typography>
                <div>
                    <List component="nav" className={classes.listRoot} aria-label="contacts">
                        {contactDetails.map(({ Icon, name, label, url }) => (
                            <ListItem
                                button
                                component={Link}
                                href={url}
                                target="_blank"
                                rel="noopener"
                            >
                                <ListItemIcon>
                                    <Icon className={classes.iconSize} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={label}
                                    secondary={<div>
                                        <span className={classes.secondaryText}>{name}</span>
                                        {label && <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => {
                                                alert(`"${label}" Skopiowano do schowka!`);
                                                return label ? copyToClipBoard(label) : _.noop();
                                            }}
                                        >
                                            {'Skopiuj'}
                                        </Button>
                                        }
                                    </div>}
                                />
                            </ListItem>
                        ))}
                    </List>
                </div>
            </div>
        </div>
    );
};

export default InviteUs;