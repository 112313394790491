import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumb from '../../common/Breadcrumb';
import { Divider } from '@material-ui/core';
import MediaCard from '../../common/MediaCard';
import { getPublicDataUrl } from '../../common/utils';
import '../home/Home.css';

const Partners = ({ breadcrumbInfo }) => {
    const [partnersData, setPartnersData] = useState({});

    useEffect(() => {
        fetch(getPublicDataUrl('Partners'))
            .then(response => response.json())
            .then(json => setPartnersData(json));
    }, []);

    return (
        <div className="Home">
            <Breadcrumb Icon={breadcrumbInfo.Icon} label={breadcrumbInfo.label} />
            <Divider />
            <div className="Pages-content">
                <Typography variant="h4" gutterBottom>
                    {"Partnerzy"}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    {partnersData.partnersTitle || "Nasi partnerzy oraz patroni medialni:"}
                </Typography>
                <div className="Home-social-media">
                    {(partnersData.partnersData || []).map(data => (
                        <MediaCard
                            style={{ margin: '0 10px 10px 0' }}
                            height={100}
                            {...data}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Partners;