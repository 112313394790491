import _ from 'lodash';
export default class AudioPlayer {

    constructor () {
        this.audio = new Audio();
    }

    play (url) {
        this.audio.src = url;
        this.audio.play();
    }

    stop () {
        this.audio.pause();
        this.audio.currentTime = 0;
        this.audio.src = '';
        this.audio.onended = _.noop;
    }

    setEndAction (callback) {
        this.audio.onended = () => {
            callback();
        };
    }
}