import _ from 'lodash';
import React from 'react';
import Parser from 'html-react-parser';
import Breadcrumb from '../../common/Breadcrumb';
import { Typography, Divider, CardMedia, Card } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import { getPublicDataUrl } from '../../common/utils';
import './About.css';

const About = ({ breadcrumbInfo }) => {
    const [aboutData, setAboutData] = useState({});

    useEffect(() => {
        fetch(getPublicDataUrl('About'))
            .then(response => response.json())
            .then(json => setAboutData(json));
    }, []);

    return (
        <div>
            <Breadcrumb Icon={breadcrumbInfo.Icon} label={breadcrumbInfo.label} />
            <Divider />
            <div className="Pages-content">
                <div className="header-image">
                    <Card>
                        <CardMedia
                            component="img"
                            alt="Troche historii"
                            image="img/about-img.jpg"
                            title="Troche historii..."
                        />
                    </Card>
                </div>
                <div className="MuiContainer-root MuiContainer-maxWidthMd">
                    <Typography variant="h2" style={{ marginTop: '20px' }}>
                        {aboutData.title || "Trochę historii..."}
                    </Typography>
                    <Typography variant="subtitle1" style={{ marginBottom: '20px' }} gutterBottom>
                        {aboutData.subtitle || "Anioły Nadziei, 05.05.2020r"}
                    </Typography>
                    {(aboutData.paragraphs || []).map((paragraph, id) => {
                        const image = paragraph.image && (
                            <div className={`About-paragraph-image ${paragraph.text ? (id % 2) ? '' : 'right' : 'center'}`}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        image={paragraph.image}
                                    />
                                </Card>
                            </div>
                        );
                        return (
                            <div className="About-paragraph">
                                {id % 2 !== 0 && image}
                                {paragraph.text && (
                                    <Typography key={_.uniqueId('body-')} variant="body1" align="justify">
                                        {Parser(paragraph.text || "")}
                                    </Typography>)
                                }
                                {id % 2 === 0 && image}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default About;