import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
});

const MediaCard = ({ image, title, text, footer, link, width, height = 140, style = {}, children }) => {
    const classes = useStyles();

    return (
        <Card className={classes.root} style={{ maxWidth: width, ...style }}>
            <CardActionArea component={Link} href={link} target="_blank" rel="noopener">
                {image && <CardMedia
                    component="img"
                    alt="CardMedia-Image"
                    height={height}
                    src={image}
                    title="CardMedia-Image"
                />}
                <CardContent>
                    {title && <Typography gutterBottom variant="h5" component="h2">
                        {title}
                    </Typography>
                    }
                    {text && <Typography variant="body2" color="textSecondary" component="p">
                        {text}
                    </Typography>}
                    {children}
                </CardContent>
            </CardActionArea>
            {footer && <CardActions>
                {footer}
            </CardActions>}
        </Card>
    );
}

export default MediaCard;