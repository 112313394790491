import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

export const APP_ID = 165300467208040;
export const APP_TOKEN = 'EAADyFoRnjVYBO14DZCAV0ZCqUArIxITHdN3BOUAz26ZAllu2OmRT0vwTJk82I9F4qUoRMdlCXO5tazH5MrHciNHCkNYB6ULQ4YgWmUWVdygcLU4wCdTY9RhLTwNlXzphsdcBlF1mYiAgm2eRpeahayQtpBjDcVTSibzTfB1HM0v0kFISpBbhXzeW9nAb8cm9cZBbezRv5JnIhiqdAT0C8LKLcv4Q2pLeGMgbyIrz';
export const GRAPH_API_BASE_URL = 'https://graph.facebook.com';
export const GRAPH_API_VERSION = 'v20.0';
export const axClient = axios.create({
    baseURL: GRAPH_API_BASE_URL,
});

axClient.interceptors.request.use((config) => {
    config.params = config.params || {};
    config.params['access_token'] = APP_TOKEN;
    return config;
});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
