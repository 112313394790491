import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Breadcrumb from '../../common/Breadcrumb';
import { Typography, Divider } from '@material-ui/core';
import AlbumCard from '../../common/AlbumCard';
import { getPublicDataUrl } from '../../common/utils';
import './Albums.css';

const Albums = ({ breadcrumbInfo }) => {
    const [albums, setAlbums] = useState([]);

    useEffect(() => {
        fetch(getPublicDataUrl('Albums'))
            .then(response => response.json())
            .then(json => setAlbums(json))
    }, []);

    return (
        <div>
            <Breadcrumb Icon={breadcrumbInfo.Icon} label={breadcrumbInfo.label} />
            <Divider />
            <div className="Pages-content">
                <Typography variant="h4" gutterBottom>
                    Dyskografia
                </Typography>
                <div className="Home-social-media">
                    {albums.map(album => <div className="Albums-album-cover">
                        <AlbumCard {...album} />
                    </div>)}
                </div>
            </div>
        </div>
    );
};

export default Albums;