import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { getLatestPosts } from './requests';
import { axClient } from '../..';
import MediaCard from '../../common/MediaCard';
import { makeStyles, Typography } from '@material-ui/core';
import './Home.css';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const Post = (props) => {
    const { message, created_time, attachments } = props;
    const classes = useStyles();

    const formatter = new Intl.DateTimeFormat('pl', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });

    const cardProps = {
        style: {
            width: '100%',
            maxWidth: '100%',
            marginBottom: '15px'
        },
        image: _.get(attachments, 'data[0].media.image.src'),
        height: _.get(attachments, 'data[0].media.image.height'),
        link: _.get(attachments, 'data[0].url'),
    };

    return (
        <MediaCard
            {...cardProps}
        >
            < Typography className={classes.title} color="textSecondary" gutterBottom >
                {formatter.format(new Date(created_time))}
            </Typography >
            <p className="Home-news MuiTypography-root MuiTypography-body1">{message}</p>
        </MediaCard >
    );
};

const News = () => {
    const [fbPosts, setFbPosts] = useState({});

    useEffect(() => {
        axClient(getLatestPosts())
            .then(({ data }) => {
                setFbPosts(data);
            })
            .catch(error => console.error(error));
    }, []);
 
    if (_.get(fbPosts, 'data', []).length === 0) {
        return (
            <Typography variant="body1" color="textSecondary" >
                {"Brak nowych informacji"}
            </Typography>
        )
    }

    return (
        <div className="MuiContainer-maxWidthSm Home-news">
            {_.get(fbPosts, 'data', []).map(props => (
                <Post key={_.uniqueId('post-')} {...props} />
            ))}
        </div>
    );
};

export default News;