import _ from 'lodash';
import React from 'react';
import { emphasize, withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import { IconButton, Link } from '@material-ui/core';
import { mediaLinksExtended } from '../App';
import './Breadcrumb.css';


const StyledBreadcrumb = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.grey[100],
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
}))(Chip);

const Breadcrumb = (props) => {
    const { Icon, label } = props;
    const mapMediaLinks = ({ MediaIcon, path }) => (
        <IconButton key={_.uniqueId('media-')} color="primary" component={Link} href={path} target="_blank" rel="noopener">
            <MediaIcon />
        </IconButton>
    );
    return (
        <div className="Breadcrumb-root">
            <div className="Breadcrumb-menu">
                <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb
                        label={label}
                        icon={<Icon fontSize="small" />}
                    />
                </Breadcrumbs>
            </div>
            <div className="Breadcrumb-socialmedia">
                {mediaLinksExtended.map(mapMediaLinks)}
            </div>
        </div>
    );
}

export default Breadcrumb;