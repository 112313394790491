import _ from 'lodash';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { CardActions, Button, Fab, IconButton, Link } from '@material-ui/core';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import AlbumDetailsDialog from './AlbumDetailsDialog';
import { musicMediaLinks } from '../App';

const supportedPlatforms = ["Youtube", "YoutubeMusic", "AppleMusic", "Spotify", "Deezer", "Tidal"];

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap-reverse'
        }
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1'
    },
    content: {
        flex: '1 0 auto',
        minWidht: 215
    },
    cover: {
        [theme.breakpoints.up('xs')]: {
            minWidth: 190,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
        minHeight: 190
    },
    margin: {
        margin: theme.spacing(1)
    },
    extendedIcon: {
        marginRight: theme.spacing(1)
    }
}));

const AlbumCard = (props) => {
    const { name, year, description, picture, songs = [], platforms = [] } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Card className={classes.root}>
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Typography component="h5" variant="h5">
                            {name}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            {year}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {description}
                        </Typography>
                        {platforms.length !== 0 && (
                            <Typography variant="subtitle2" color="textSecondary">
                                {'Dostępne na platformach:'}
                                {platforms
                                    .filter(entry => supportedPlatforms.includes(entry))
                                    .map(platform => {
                                        const platformData = musicMediaLinks.find(entry => entry.index === platform);
                                        if (!platformData) return <div></div>;
                                        return (
                                            <IconButton key={_.uniqueId('media-')} color="primary" component={Link} href={platformData.path} target="_blank" rel="noopener">
                                                <platformData.MediaIcon />
                                            </IconButton>
                                        );
                                    })
                                }
                            </Typography>
                        )}
                    </CardContent>
                </div>
                {songs.length > 0 && <CardActions>
                    <Fab
                        variant="extended"
                        color="primary"
                        aria-label="play"
                        className={classes.margin}
                        component={Button}
                        onClick={handleClickOpen}
                    >
                        <PlayCircleFilledWhiteIcon className={classes.extendedIcon} />
                        {'Posłuchaj'}
                    </Fab>
                </CardActions>}
                <CardMedia
                    className={classes.cover}
                    image={picture}
                    title={name}
                />
            </Card>
            <AlbumDetailsDialog open={open} handleClose={handleClose} album={props} />
        </div>
    );
}

export default AlbumCard;