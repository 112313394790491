import _ from 'lodash';
import React, { useState } from 'react';
import { Slide, Dialog, DialogContent, DialogContentText, DialogActions, Button, CardMedia, makeStyles, IconButton, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import AudioPlayer from './AudioPlayer';

const useStyles = makeStyles(theme => ({
    media: {
        height: '40vh',
        minHeight: '260px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'
    },
    currentSong: {
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        width: '100%',
        justifyContent: 'center',
        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
        flex: 'auto'
    },
    controlsContainer: {
        display: 'flex',
        flexDirection: 'column',
        background: 'rgba(0, 0, 0, 0.54)'
    },
    controls: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        height: '60px'
    },
    controlButton: {
        color: 'white'
    },
    playIcon: {
        height: 58,
        width: 58,
        color: 'white'
    },
    listRoot: {
        width: "100%"
    },
    iconSize: {
        fontSize: 24
    },
    expansionPanelDetailsRoot: {
        maxHeight: '25vh',
        overflow: 'auto'
    }
}));

const Transition = React.forwardRef(function Transition (props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const AlbumDetailsDialog = ({ open, handleClose, album }) => {
    const classes = useStyles();
    const [currentSong, setCurrentSong] = useState('');
    const [audioPlayer] = useState(new AudioPlayer());
    const [songStarted, startSong] = useState(false);
    const { name, description, picture, songs = [] } = album;

    const playSong = (title, mp3) => {
        if (songs.length > 0) {
            const play = (songName, songPath) => {
                setCurrentSong(songName);
                audioPlayer.play(songPath);
                startSong(true);
            }
            if (title && mp3) {
                play(title, mp3);
                return true;
            }
            if (_.isEmpty(currentSong) && _.has(songs[0], 'mp3')) {
                play(songs[0].title, songs[0].mp3);
            }
        }
    };

    const stopSong = () => {
        setCurrentSong('');
        audioPlayer.stop();
        startSong(false);
    };

    const nextSong = () => {
        if (!_.isEmpty(currentSong)) {
            stopSong();
            const nextIndex = _.findIndex(songs, ['title', currentSong]) + 1;
            if (songs[nextIndex]) {
                const { title, mp3 } = songs[nextIndex];
                playSong(title, mp3);
            } else {
                playSong()
            }
        }
    }

    const prevSong = () => {
        if (!_.isEmpty(currentSong)) {
            stopSong();
            const prevIndex = _.findIndex(songs, ['title', currentSong]) - 1;
            if (songs[prevIndex]) {
                const { title, mp3 } = songs[prevIndex];
                playSong(title, mp3);
            } else {
                playSong()
            }
        }
    }

    const onClose = () => {
        stopSong();
        handleClose();
    };

    audioPlayer.setEndAction(nextSong);

    const cover = (
        <CardMedia
            className={classes.media}
            image={picture}
            title={name}
        >
            <div className={classes.controlsContainer}>
                <div className={classes.controls}>
                    <IconButton aria-label="previous" onClick={prevSong}>
                        <SkipPreviousIcon className={classes.controlButton} />
                    </IconButton>
                    {songStarted ?
                        <IconButton aria-label="play" onClick={stopSong}>
                            <PauseIcon className={classes.playIcon} />
                        </IconButton> :
                        <IconButton aria-label="pause" onClick={playSong}>
                            <PlayArrowIcon className={classes.playIcon} />
                        </IconButton>
                    }
                    <IconButton aria-label="next" onClick={nextSong}>
                        <SkipNextIcon className={classes.controlButton} />
                    </IconButton>
                </div>
                {!_.isEmpty(currentSong) && <div className={classes.currentSong}>
                    <VolumeUpIcon style={{ marginRight: 10 }} />
                    <Typography>{currentSong}</Typography>
                </div>}
            </div>
        </CardMedia>
    );

    const songList = (
        <ExpansionPanel className="AlbumDetailsDialog-songs">
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <QueueMusicIcon color="action" style={{ marginRight: 10 }} />
                <Typography>{'Lista utworów'}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetailsRoot}>
                <List component="nav" className={classes.listRoot} aria-label="songs">
                    {songs.length > 0 ? songs.map(({ title, mp3 }) => (
                        <ListItem
                            button
                            onClick={() => playSong(title, mp3)}
                            selected={_.isEqual(currentSong, title)}
                        >
                            <ListItemIcon>
                                {_.isEqual(currentSong, title) ?
                                    <PlayArrowIcon className={classes.iconSize} /> :
                                    <AudiotrackIcon className={classes.iconSize} />
                                }
                            </ListItemIcon>
                            <ListItemText primary={title} />
                        </ListItem>
                    )) : 'Brak dodanych utworów.'}
                </List>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );

    const content = (
        <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                {description}
            </DialogContentText>
        </DialogContent>
    );

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            {cover}
            {songList}
            {content}
            <DialogActions>
                <Button onClick={onClose} color="primary" startIcon={<KeyboardReturnIcon />}>
                    {'Powrót'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AlbumDetailsDialog;