import _ from 'lodash';
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import MiniDrawer from './MiniDrawer';
import './RootContainer.css';
import { PUBLIC_CURRENT_VERSION } from '../../properties';

const PRIVACY_POLICY_LINK = 'https://www.termsfeed.com/live/a200fe03-bfbf-40d7-8edc-e69dfe4df4b0';

const RootContainer = (props) => {
    const links = props.pages;
    const mapLinks = ({ Icon, label, path }) => (
        <ListItem key={_.uniqueId('link-')} button component={Link} to={path}>
            <ListItemIcon>
                <Icon />
            </ListItemIcon>
            <ListItemText primary={label} />
        </ListItem>
    );
    const appVersion = PUBLIC_CURRENT_VERSION;
    const privacyPolicyLink = <a className="privacy-policy-link" href={PRIVACY_POLICY_LINK} target="_blank" rel="noopener noreferrer">Privacy Policy</a>;
    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="lg">
                <div className="RootContainer-root">
                    <div className="RootContainer-header">
                        <div className="RootContainer-logo"></div>
                    </div>
                    <Paper className="RootContainer-container">
                        <MiniDrawer>{links.map(mapLinks)}</MiniDrawer>
                        <div className="RootContainer-pages MuiPaper-root MuiPaper-elevation16 MuiDrawer-paper MuiDrawer-paperAnchorLeft">
                            {props.children}
                        </div>
                    </Paper>
                    <Paper className="RootContainer-bottom">
                        {`AN v${appVersion} Copyright © 2020 Wszelkie prawa zastrzeżone • `}{privacyPolicyLink}
                    </Paper>
                </div>
            </Container>
        </React.Fragment>
    );
};

export default RootContainer;